import { FetchMoreOptions, useMutation, useQuery } from '@apollo/client';
import Layout from '@layouts';
import { getQueryName } from '@lib';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { FC, ReactNode, useState } from 'react';

import {
  GetCategoriesDocument,
  HandleUserSignupDocument,
  UsersAwaitingConfirmationDocument,
  UsersAwaitingConfirmationQuery,
  UsersAwaitingConfirmationQueryVariables,
} from '@redsleeve/oilynx-domain';

import Button from '@components/Button';

import {
  COUNTRY_UNDEFINED,
  countryValues,
  mapCountryToSelectValue,
} from '@data/countries';

import { getErrorMessages } from '@lib/errors';
import { useOrderedCategories } from '@lib/hooks/useOrderedCategories';

import './signup-management.css';

const ITEM_PER_PAGE = 50;

enum SortType {
  nameASC = 'name',
  nameDESC = '-name',
  companyASC = 'companyName',
  companyDESC = '-companyName',
  interestASC = 'interest',
  interestDESC = '-interest',
}

const SignupManagementPage: FC = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(query);
  const categoriesQuery = useQuery(GetCategoriesDocument);
  const orderedCategories = useOrderedCategories(categoriesQuery.data);
  const [sortType, setSortType] = useState<SortType>(SortType.nameASC);
  const [usersLeft, setUsersLeft] = useState(0);
  const usersQuery = useQuery(UsersAwaitingConfirmationDocument, {
    variables: {
      pagination: {
        first: ITEM_PER_PAGE,
        offset: 0,
        sort: sortType,
      },
    },

    ssr: false,
  });
  const [handleUserSignup] = useMutation(HandleUserSignupDocument, {
    refetchQueries: [getQueryName(UsersAwaitingConfirmationDocument)],
  });
  const changeStatusHandler = async (id: string, status: any) => {
    try {
      await handleUserSignup({
        variables: { id, status, origin: siteMetadata.siteUrl },
      });
    } catch (ex) {
      const errorMessages = getErrorMessages(ex);
      if (errorMessages) alert(errorMessages.join('\n'));
      else console.error('[Admin Signup Management page]', ex);
    }
  }; //state prop & sort column

  const scrollDown = () => {
    let updateQuery: FetchMoreOptions<
      UsersAwaitingConfirmationQuery,
      UsersAwaitingConfirmationQueryVariables
    >['updateQuery'] = (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;
      const usersAwaitingLeft = {
        usersAwaitingConfirmation: [
          ...prev.usersAwaitingConfirmation,
          ...fetchMoreResult.usersAwaitingConfirmation,
        ],
      };
      setUsersLeft(usersAwaitingLeft.usersAwaitingConfirmation.length);
      return usersAwaitingLeft;
    };
    usersQuery.fetchMore({
      variables: {
        pagination: {
          first: ITEM_PER_PAGE,
          offset: usersQuery.data?.usersAwaitingConfirmation?.length,
          sort: sortType,
        },
      },
      updateQuery,
    });
  };
  const getRows = () => {
    let rows: ReactNode[] = [];
    if (usersQuery.data)
      usersQuery.data.usersAwaitingConfirmation.map((user) => {
        const flag =
          countryValues.find(
            (country) => country.value.isoCode === user.country
          ) ?? mapCountryToSelectValue(true, COUNTRY_UNDEFINED);
        rows.push(
          <tr key={user.id} className={'TableRowData hover:bg-background-avg'}>
            <td
              className={
                'Tooltip-wrapper border-b font-body  text-left text-sm text-white truncate border-gray-700  '
              }
            >
              {user.name}
              {user.name.length > 18 && (
                <span className="invisible w-48  mt-5  rounded absolute z-10 bg-background-dark text-interaction-transparent text-center">
                  {user.name}
                </span>
              )}
            </td>
            <td
              className={
                'Tooltip-wrapper font-body text-left text-sm text-white truncate border-gray-700 '
              }
            >
              {user.companyName}, {user.companyAddress}
              {/*{user.companyName.length + user.companyAddress.length > 18 && (*/}
              <span className="invisible w-48 mt-5 rounded absolute z-10 bg-background-dark text-interaction-transparent text-center">
                {user.companyName}, {user.companyAddress}
              </span>
              {/*)}*/}
            </td>

            <td
              className={
                'Tooltip-wrapper font-body text-left text-sm text-white truncate border-gray-700  '
              }
            >
              {user.roles.join(', ')}
              <span className="invisible w-48 ml-40 mt-5 -ml-5 border-blue-50 rounded absolute z-10 bg-background-dark text-interaction-transparent text-center">
                {user.roles.join(', ')}
              </span>
            </td>

            <td
              className={
                'Tooltip-wrapper font-body text-left text-sm text-white truncate border-gray-700  '
              }
            >
              {user.roleDescription?.length
                ? user.roleDescription
                : user.interests.map((interest, index) => {
                    return (
                      <span key={Math.floor(Math.random() * 1000) + 1}>
                        {(index ? ', ' : '') +
                          orderedCategories.find((item) => item.id === interest)
                            ?.name}
                      </span>
                    );
                  })}
              <span className="invisible w-48 ml-40 mt-5 -ml-5 border-blue-50 rounded absolute z-10 bg-background-dark text-interaction-transparent text-center">
                {user.roleDescription?.length
                  ? user.roleDescription
                  : user.interests.map((interest, index) => {
                      return (
                        <span key={Math.floor(Math.random() * 1000) + 1}>
                          {(index ? ', ' : '') +
                            orderedCategories.find(
                              (item) => item.id === interest
                            )?.name}
                        </span>
                      );
                    })}
              </span>
            </td>
            <td
              className={
                'font-body text-left text-sm text-white truncate border-gray-700 align-center  '
              }
            >
              <span className="flex  items-center ">
                <img
                  className={'w-5 h-5 mb-0'}
                  alt={`flag of ${flag.value.name}`}
                  src={flag.value.flag}
                />
                <span className="pl-3">{user.phoneNumber}</span>
              </span>
            </td>
            <td
              className={
                'Tooltip-wrapper font-body text-left text-sm text-white border-gray-700 truncate'
              }
            >
              {user.email}
              {/*{user.email.length > 18 && (*/}
              <span className="invisible w-48  mt-5 rounded absolute z-10 bg-background-dark text-interaction-transparent text-center">
                {user.email}
              </span>
              {/*)}*/}
            </td>
            <td
              className={'border-b border-t border-gray-700 truncate pt-0 pb-0'}
            >
              <button
                className="EnabledButton w-7 h-7 bg-background-avg rounded-sm"
                onClick={() => changeStatusHandler(user.id, 'ENABLED')}
              >
                <span className="text-interaction-light .parent:hover">
                  &#10003;
                </span>
              </button>
              <button
                className="DisabledButton w-7 h-7 bg-background-avg  rounded-sm focus-within:border-interaction-reject hover:bg-interaction-reject ml-1	"
                onClick={() => changeStatusHandler(user.id, 'DISABLED')}
              >
                <span className="text-interaction-light">&#10005;</span>
              </button>
            </td>
          </tr>
        );
      });
    return rows;
  };

  return (
    <Layout
      url="/admin/signup-management/"
      title="Signup Management"
      disableIndex
    >
      <p className="text-center text-white font-emp opacity-100 capitalize tracking-normal font-bold text-3xl">
        Sign Up Panel
      </p>
      <table className="w-full table-fixed whitespace-nowrap Table-wrapper">
        <tbody>
          <tr>
            <th
              className={
                'TableFullNameCol text-sm font-emp  text-gray-500 border-b  border-gray-700'
              }
            >
              <div className={'flex'}>
                <div
                  className={'text-left  fontFamily '}
                  onClick={() => {
                    sortType === SortType.nameASC
                      ? setSortType(SortType.nameDESC)
                      : setSortType(SortType.nameASC);
                  }}
                >
                  Full Name
                </div>
                <div>
                  <div className={'flex-col text-xs leading-3 pl-1 '}>
                    <div>
                      <button onClick={() => setSortType(SortType.nameASC)}>
                        ▲
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setSortType(SortType.nameDESC)}>
                        ▼
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th
              className={
                'text-sm font-emp text-gray-500 border-b  border-gray-700'
              }
            >
              <div className={'flex'}>
                <div
                  className={'text-left fontFamily '}
                  onClick={() => {
                    sortType === SortType.companyASC
                      ? setSortType(SortType.companyDESC)
                      : setSortType(SortType.companyASC);
                  }}
                >
                  Company Details
                </div>
                <div>
                  <div className={'flex-col leading-3 text-xs pl-1'}>
                    <div>
                      <button onClick={() => setSortType(SortType.companyASC)}>
                        ▲
                      </button>
                    </div>
                    <div>
                      <button onClick={() => setSortType(SortType.companyDESC)}>
                        ▼
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th
              className={
                'text-sm font-emp text-gray-500 border-b   border-gray-700'
              }
            >
              <div className={'flex'}>
                <div className={'text-left fontFamily '}>Type</div>
              </div>
            </th>
            <th
              className={
                'text-sm font-emp text-gray-500 border-b   border-gray-700'
              }
            >
              <div className={'flex'}>
                <div className={'text-left fontFamily '}>Products / Role</div>
                <div>
                  <div className={'flex-col leading-3 text-xs pl-1'}>
                    <div>
                      <button onClick={() => setSortType(SortType.interestASC)}>
                        ▲
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() => setSortType(SortType.interestDESC)}
                      >
                        ▼
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th
              className={
                'text-sm font-emp text-gray-500 border-b leading-3   border-gray-700'
              }
            >
              Phone Number
            </th>
            <th
              className={
                'text-sm font-emp text-gray-500 border-b leading-3   border-gray-700'
              }
            >
              Email
            </th>
            <th className={' border-b w-1/12 border-gray-700'} />
          </tr>
          {getRows()}
        </tbody>
      </table>
      <div className="text-center">
        {usersLeft === usersQuery.data?.usersAwaitingConfirmation?.length ? (
          <p className="text-white">No more users</p>
        ) : (
          <Button
            type="submit"
            name="submit"
            className="hover:bg-interaction-enabled hover:text-white"
            onClick={scrollDown}
          >
            Load more
          </Button>
        )}
      </div>
    </Layout>
  );
};
const query = graphql`
  query SiteQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
export default SignupManagementPage;
